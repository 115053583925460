import { useState, useEffect } from 'react';
// AWS
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   linkTo: '/',
  // },
  // {
  //   label: 'Settings',
  //   linkTo: '/',
  // },
];

// ----------------------------------------------------------------------

type Props = {
  user: CognitoUser | null;
};

export default function AccountPopover({user}: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [email, setEmail] = useState<null | string>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = (): void => {
    Auth.signOut().then(() => {
      console.log('LOGOUT');
      window.location.replace('/');
    });
  };

  useEffect(() => {
    if (user) {
      user.getUserAttributes((err, data) => {
        const attMap = new Map<string, string>();
        data?.forEach((r) => { // CognitoUserAttribute
          if (r && r.Name) {
            attMap.set(r.Name as string, r.Value as string)
          }
        });
        setEmail(attMap.get('email') || null);
      });
    }
  }, []);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          // src="https://minimal-assets-api.vercel.app/assets/images/avatars/avatar_5.jpg"
          alt={user?.getUsername() || 'n/a'}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.getUsername() || 'n/a'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email || 'n/a'}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>Logout</MenuItem>
      </MenuPopover>
    </>
  );
}
