// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  wallet: getIcon('wallet'),
  burn: getIcon('burn'),
  mint: getIcon('ic_ecommerce'),
  transactions: getIcon('transactions'),
  balance: getIcon('balance'),
  system: getIcon('system'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking'),
  kanban: getIcon('ic_kanban'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Core',
    items: [
      { title: 'Wallet Providers', path: '/dashboard/providers', icon: ICONS.wallet },
      { title: 'Mint Funds', path: '/dashboard/mint', icon: ICONS.mint },
      { title: 'Payout', path: '/dashboard/payout', icon: ICONS.burn },
      { title: 'Transactions', path: '/dashboard/transactions', icon: ICONS.transactions },
      { title: 'Balances', path: '/dashboard/balances', icon: ICONS.balance },
      { title: 'Treasury Operations', path: '/dashboard/operations', icon: ICONS.banking },
      { title: 'System', path: '/dashboard/system', icon: ICONS.system },
    ],
  },
];

export default navConfig;
