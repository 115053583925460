// @mui
import { Stack } from '@mui/material';
// import { Stack, Button, Typography } from '@mui/material';
// assets
// import { PnpLogo } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'left', display: 'block' }}
    >
      {/* <PnpLogo sx={{ width: 1 }} /> */}
    </Stack>
  );
}


// export default function NavbarDocs() {
//   return (
//     <Stack
//       spacing={3}
//       sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
//     >
//       <PnpLogo sx={{ width: 1 }} />

//       <div>
//         <Typography gutterBottom variant="subtitle1">
//           Hi, Dawid Planeta
//         </Typography>
//         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//           Need help?
//           <br /> Please check our docs
//         </Typography>
//       </div>

//       <Button variant="contained">Documentation</Button>
//     </Stack>
//   );
// }
