import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// MUI
import { Button } from '@mui/material';
// AWS
// import { Auth } from 'aws-amplify';
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { CognitoUser } from '@aws-amplify/auth';
import Amplify from 'aws-amplify';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// AWS Settings
import awsconfig from '../aws-exports';
// components
import LoadingScreen from '../components/LoadingScreen';
import Login from '../pages/auth/Login';

awsconfig.aws_appsync_authenticationType = 'AMAZON_COGNITO_USER_POOLS';
Amplify.configure(awsconfig);

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

type PropsType = {
  user: CognitoUser | null;
};

const Router = ({ user }: PropsType): React.ReactElement | null => {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/providers" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout user={user}/>,
      children: [
        { element: <Navigate to="/dashboard/providers" replace />, index: true },
        { path: 'providers', element: <Providers /> },
        { path: 'mint', element: <Mint /> },
        { path: 'payout', element: <Payout /> },
        { path: 'transactions', element: <Transactions /> },
        { path: 'balances', element: <Balance /> },
        { path: 'operations', element: <Operations user={user}/> },
        { path: 'system', element: <System /> },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/paymentcheck" replace />, index: true },
        //     { path: 'transactions', element: <CustomerTransactionsPage user={user} /> },
        //     { path: 'paymentcheck', element: <CustomerPaymentCheckPage /> },
        //     { path: 'settlepayment', element: <CustomerSettlePaymentPage user={user} /> },
        //     { path: 'refundrequest', element: <CustomerRefundRequestPage /> },
        //     { path: 'defund', element: <CustomerDefundPage /> },
        //     { path: 'cognito', element: <CustomerCognitoPage user={user} /> },
        //   ],
        // },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard - Admin
const Providers = Loadable(lazy(() => import('../pages/Providers')));
const Mint = Loadable(lazy(() => import('../pages/Mint')));
const Payout = Loadable(lazy(() => import('../pages/Payout')));
const Transactions = Loadable(lazy(() => import('../pages/Transactions')));
const Balance = Loadable(lazy(() => import('../pages/Balance')));
const System = Loadable(lazy(() => import('../pages/System')));
const Operations = Loadable(lazy(() => import('../pages/Operations')));
// Utils page
const NotFound = Loadable(lazy(() => import('../pages/Page404')));


const CognitoAuthenticatorProvider = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  return (
    <View>
      {
        user ?
          <Router user={user || null} />:
          <Login />
      }
    </View>
  );
};

const CognitoAuthenticatorWrapper = () => {
  return (
    <Authenticator.Provider>
      <CognitoAuthenticatorProvider />
    </Authenticator.Provider>
  );
};

export default CognitoAuthenticatorWrapper;