import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { PnpLogo } from '../assets';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  let logo = null;
  // Always West at this stage.
  if (true) {
    logo = (
      <Box sx={{ width: 50, height: 50, ...sx }}>
        <PnpLogo />
      </Box>
    );
  } else {
    logo = (
      <Box sx={{ width: 40, height: 40, ...sx }}>
        <svg width="134" height="34" viewBox="0 0 134 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1423_27866)">
            <path d="M18.425 9.8769C19.0778 9.89935 19.7285 9.78823 20.3382 9.55015C20.9478 9.31207 21.5041 8.95191 21.9737 8.49111C22.4434 8.03032 22.8169 7.4783 23.072 6.86795C23.327 6.2576 23.4585 5.60139 23.4585 4.9384C23.4585 4.27541 23.327 3.61921 23.072 3.00885C22.8169 2.3985 22.4434 1.84649 21.9737 1.38569C21.5041 0.924895 20.9478 0.564733 20.3382 0.326656C19.7285 0.0885793 19.0778 -0.0225446 18.425 -9.62086e-05H9.49165C3.64032 -9.62086e-05 -1.74224e-05 5.37757 -1.74224e-05 10.8006C-0.0242393 12.4206 0.283782 14.0281 0.904482 15.5209C2.41198 12.2966 5.37115 9.85424 9.27948 9.85424L18.425 9.8769Z" fill="url(#paint0_linear_1423_27866)"/>
            <path d="M9.70383 21.9526C7.91111 21.8993 6.16707 22.549 4.83408 23.7668C3.50109 24.9846 2.68164 26.6769 2.546 28.4919C2.546 28.7583 2.5125 29.0246 2.5125 29.3023C2.5125 29.5799 2.5125 29.8689 2.55717 30.1749C0.960334 28.4069 0 25.9816 0 22.8763C0 17.4533 3.6515 12.0756 9.49167 12.0756H18.425C19.0779 12.0531 19.7285 12.1643 20.3382 12.4023C20.9478 12.6404 21.5041 13.0006 21.9737 13.4614C22.4434 13.9222 22.8169 14.4742 23.072 15.0845C23.3271 15.6949 23.4585 16.3511 23.4585 17.0141C23.4585 17.6771 23.3271 18.3333 23.072 18.9436C22.8169 19.554 22.4434 20.106 21.9737 20.5668C21.5041 21.0276 20.9478 21.3878 20.3382 21.6258C19.7285 21.8639 19.0779 21.975 18.425 21.9526H9.70383Z" fill="url(#paint1_linear_1423_27866)"/>
            <path d="M10.2175 24.1284H18.6539C19.9437 24.1284 21.1806 24.6484 22.0926 25.574C23.0046 26.4997 23.517 27.7551 23.517 29.0641C23.517 30.3731 23.0046 31.6285 22.0926 32.5541C21.1806 33.4797 19.9437 33.9998 18.6539 33.9998H10.1896C5.8234 33.9261 4.09814 31.6368 4.09814 29.2284C4.09814 26.2251 6.47665 24.1284 10.2175 24.1284Z" fill="url(#paint2_linear_1423_27866)"/>
            <path d="M49.2897 26.7294H51.8413V33.5691H34.6167V30.7924H37.1069V14.8011H34.6167V12.1831H51.8301V18.5298H49.2785V14.8011H40.1219V21.3008H48.3182V23.8621H40.1219V30.7924H49.2897V26.7294Z" fill="white"/>
            <path d="M63.2312 32.1525C61.9334 33.3247 60.2617 33.9808 58.5245 33.9999C55.61 33.9999 53.667 32.0562 53.667 29.2229C53.667 26.2649 55.7607 24.2929 58.9209 24.2929C60.3818 24.3813 61.8134 24.7464 63.1419 25.3695V24.4799C63.1419 21.7939 62.1983 20.5132 60.0097 20.5132C59.2981 20.4933 58.5937 20.6621 57.9661 21.003C57.3384 21.3438 56.8092 21.845 56.4307 22.4569L53.8457 21.7769C54.7892 19.4705 57.0672 18.1445 60.2274 18.1445C64.4428 18.1445 65.8107 20.2072 65.8107 24.3779V30.8775H68.0441V33.5295H63.2145L63.2312 32.1525ZM63.2312 28.2652C61.8633 27.4379 60.7411 26.9109 59.4346 26.9109C57.7037 26.9109 56.5815 27.8345 56.5815 29.1322C56.5815 30.2995 57.553 31.2289 59.0102 31.2289C60.2553 31.2289 61.6232 30.5772 63.2312 29.3759V28.2652Z" fill="white"/>
            <path d="M69.7917 28.4804H72.1646C72.3768 30.4524 73.8396 31.6254 76.0171 31.6254C77.8093 31.6254 78.9037 30.7017 78.9037 29.5627C78.9037 28.4237 77.9936 27.9307 76.2013 27.4944L73.9233 26.973C71.0703 26.293 69.7358 25.001 69.7358 22.6607C69.7358 20.1617 71.891 18.1614 74.8948 18.1614C76.3525 18.1308 77.7659 18.6706 78.8423 19.6687V18.5354H81.1203V23.437H78.8423C78.6915 21.6464 77.2008 20.4451 75.0456 20.4451C73.4711 20.4451 72.494 21.2157 72.494 22.3887C72.494 23.5617 73.3148 23.9924 74.8948 24.3267L77.4129 24.8537C80.2381 25.4714 81.606 26.854 81.606 29.1377C81.606 32.005 79.5123 34.0054 76.1734 34.0054C74.7047 34.0525 73.2727 33.5343 72.1646 32.5547V33.5747H69.7917V28.4804Z" fill="white"/>
            <path d="M88.8198 31.3194C89.3708 31.2923 89.9096 31.1454 90.3998 30.8887V33.5067C89.6608 33.8297 88.865 33.9974 88.0604 33.9997C85.3302 33.9997 84.9617 32.549 84.9617 29.8687V21.023H83.0187V18.5297H84.9617V16.5294L87.6361 14.2627V18.5127H90.3998V21.006H87.6584V29.3927C87.6584 30.7924 87.8762 31.3194 88.8198 31.3194Z" fill="white"/>
            <path d="M100.874 13.5998V12.1831H108.01V13.5998H105.916L110.193 31.4441L114.509 13.5998H112.225V12.1831H118.205V13.5998H116.133L111.108 33.5691H109.104L104.369 14.3024L99.6626 33.5691H97.6526L92.6834 13.5998H90.4054V12.1831H96.5471V13.5998H94.4701L98.7469 31.4441L103.063 13.5998H100.874Z" fill="#5CB900"/>
            <path d="M125.256 31.9994V33.5691H118.21V31.9994H120.823V13.5998H118.21V12.1831H125.921C131.42 12.1831 134 14.0588 134 18.4674C134 22.8761 131.42 24.8764 125.921 24.8764H122.643V31.9994H125.256ZM122.643 23.3974H125.804C130.203 23.3974 132.241 22.1678 132.241 18.5014C132.241 14.8351 130.203 13.5998 125.804 13.5998H122.643V23.3974Z" fill="#5CB900"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_1423_27866" x1="1.42373" y1="11.0726" x2="27.9655" y2="-2.00317" gradientUnits="userSpaceOnUse">
              <stop stopColor="#5CB900"/>
              <stop offset="0.45" stopColor="#3DA174"/>
              <stop offset="1" stopColor="#1784FF"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1423_27866" x1="2.28358" y1="22.4853" x2="27.6362" y2="10.5098" gradientUnits="userSpaceOnUse">
              <stop stopColor="#5CB900"/>
              <stop offset="0.45" stopColor="#3DA174"/>
              <stop offset="1" stopColor="#1784FF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1423_27866" x1="3.59006" y1="33.1951" x2="29.7252" y2="23.1941" gradientUnits="userSpaceOnUse">
              <stop stopColor="#5CB900"/>
              <stop offset="0.45" stopColor="#3DA174"/>
              <stop offset="1" stopColor="#1784FF"/>
            </linearGradient>
            <clipPath id="clip0_1423_27866">
              <rect width="134" height="34" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </Box>
    );
  }

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
